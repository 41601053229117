$(document).ready(function() {
    //Declare normal variable javascript
    //Hide element when smaller than 1025
    if ($(window).width() < 1025) {
        $(".bottom-header").fadeIn(function() {
            $(".bottom-header").css({
                display: "flex"
            });
        });
    }

    //Toggle Search
    $(".search-toggle").on("click", function() {
        $(".searchbox").toggleClass("active");
        event.preventDefault();
        $(".searchbox").addClass("open");
        $('.searchbox  > input[type="text"]').focus();
    });

    $(".searchbox, .searchbox button.close").on("click keyup", function(event) {
        if (
            event.target == this ||
            event.target.className == "close" ||
            event.keyCode == 27
        ) {
            $(this).removeClass("open");
        }
    });

    //Library init
    $(".lightgallery").lightGallery();
    //Declare function Javascript
    tabActive();
    swiperInit();
    backToTop();
    mappingHTML();
    toggleMobileMenu();
    toggleApplyForm();
    if ($(window).width() > 1024) {
        const $menu = $(".searchbox");
        $(document).mouseup(e => {
            if (!$menu.is(e.target) && // if the target of the click isn't the container...
                $menu.has(e.target).length === 0
            ) {
                // ... nor a descendant of the container
                $menu.removeClass("active");
            }
        });
    }
});
//Check if windows size large then 1024 then these function will be execute
if ($(window).width() > 1024) {
    const $menu = $(".searchbox");
    $(document).mouseup(e => {
        if (!$menu.is(e.target) && // if the target of the click isn't the container...
            $menu.has(e.target).length === 0
        ) {
            // ... nor a descendant of the container
            $menu.removeClass("active");
        }
    });
}

// Back to top
function backToTop() {
    let btn = $(".back-to-top");
    btn.on("click", function(e) {
        e.preventDefault();
        $("html, body").animate({
                scrollTop: 0
            },
            "300"
        );
    });
}
// Remove when click outside the circle

function tabActive() {
    $(".tab-navigation li a").on("click", function() {
        $(this)
            .parents(".tab-navigation")
            .find("li")
            .removeClass("active");
        $(this)
            .parents("li")
            .addClass("active");

        var display = $(this).attr("data-type");
        $(".tab-item").removeClass("active");
        $("#" + display).addClass("active");
    });
}

function toggleApplyForm() {
    $(".apply-form .button-apply a").on("click", function() {
        $(".form-apply").slideToggle();
    });
}

function swiperInit() {
    var homerSwiper = new Swiper(".banner-fluid-caption .swiper-container", {
        // Optional parameters
        speed: 1205,
        slidesPerView: 1,
        autoplay: {
            delay: 3000
        },
        pagination: {
            el: ".banner-fluid-caption-pagination",
            type: "bullets",
            clickable: "true"
        }
    });

    var swiper = new Swiper(".partner-swiper-1 .swiper-container", {
        slidesPerColumn: 3,
        spaceBetween: 30,
        watchSlidesVisibility: true,
        observer: true,
        observeParents: true,
        breakpointsInverse: true,
        breakpoints: {
            320: {
                slidesPerView: 1,
                slidesPerColumn: 2,
                spaceBetween: 15
            },
            360: {
                slidesPerView: 2,
                slidesPerColumn: 2,
                spaceBetween: 15
            },
            576: {
                slidesPerView: 2,
                slidesPerColumn: 2
            },
            768: {
                slidesPerView: 3,
                slidesPerColumn: 2
            },
            1025: {
                slidesPerView: 5
            }
        }
    });
    var swiper = new Swiper(".partner-swiper-2 .swiper-container", {
        slidesPerColumn: 3,
        spaceBetween: 30,
        watchSlidesVisibility: true,
        observer: true,
        observeParents: true,
        breakpointsInverse: true,
        breakpoints: {
            320: {
                slidesPerView: 1,
                slidesPerColumn: 2,
                spaceBetween: 15
            },
            360: {
                slidesPerView: 2,
                slidesPerColumn: 2,
                spaceBetween: 15
            },
            576: {
                slidesPerView: 2,
                slidesPerColumn: 2
            },
            768: {
                slidesPerView: 3,
                slidesPerColumn: 2
            },
            1025: {
                slidesPerView: 5
            }
        }
    });

    var topBanner = new Swiper(".top-banner .swiper-container", {
        // Optional parameters
        speed: 1205,
        slidesPerView: 1,
        autoplay: {
            delay: 3000
        }
    });
    var topBanner = new Swiper(".banner-fluid-caption-top .swiper-container", {
        // Optional parameters
        speed: 1205,
        slidesPerView: 1,
        autoplay: {
            delay: 3000
        }
    });
    var homeNews = new Swiper(".home-news .swiper-container", {
        // Optional parameters
        speed: 1205,
        slidesPerView: 1,
        autoplay: {
            delay: 3000
        },
        breakpointsInverse: true,
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            576: {
                slidesPerView: 2
            },
            769: {
                slidesPerView: 3
            },
            1025: {
                slidesPerView: 4.5
            }
        },

        spaceBetween: 10,
        navigation: {
            nextEl: ".home-news-next",
            prevEl: ".home-news-prev"
        }
    });
    var homeNews = new Swiper(".services-news .swiper-container", {
        // Optional parameters
        speed: 1205,
        autoplay: {
            delay: 3000
        },
        breakpointsInverse: true,
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            576: {
                slidesPerView: 2
            },
            769: {
                slidesPerView: 3
            },
            1025: {
                slidesPerView: 3
            }
        },

        spaceBetween: 30,
        centeredSlides: true,
        navigation: {
            nextEl: ".home-news-next",
            prevEl: ".home-news-prev"
        }
    });
    var homePartner = new Swiper(".home-partner .swiper-container", {
        // Optional parameters
        speed: 1205,
        spaceBetween: 30,
        autoplay: {
            delay: 3000
        },
        breakpointsInverse: true,
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            350: {
                slidesPerView: 2
            },
            576: {
                slidesPerView: 3
            },
            768: {
                slidesPerView: 4
            },
            1025: {
                slidesPerView: 6
            }
        }
    });
}

function toggleMobileMenu() {
    $(".mobile-toggle").on("click", function() {
        $(".mobile-wrapper").toggleClass("active");
    });
}

function mappingHTML() {
    return new MappingListener({
        selector: ".main-nav",
        mobileWrapper: ".mobile-wrapper",
        mobileMethod: "appendTo",
        desktopWrapper: ".header-nav-right",
        desktopMethod: "prependTo",
        breakpoint: 1025
    }).watch();
}